<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="投放名稱" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入投放名稱" allow-clear/>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="投放时间段(7-10,10-13)" prop="putInTime">
                <a-input v-model="queryParam.putInTime" placeholder="请输入投放时间段(7-10,10-13)" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['adv:adv:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['adv:adv:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['adv:adv:remove']">
          <a-icon type="delete" />刪除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['adv:adv:export']">
          <a-icon type="download" />導出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <browse
        ref="browse"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleStatus(record,1)" v-if="record.status == 0">
            下架
          </a>
          <a @click="handleStatus(record,0)" v-if="record.status == 1">
            上架
          </a>
          <a-divider type="vertical" v-hasPermi="['adv:adv:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['adv:adv:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['adv:adv:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['adv:adv:remove']">
            <a-icon type="delete" />刪除
          </a>
          <a-divider type="vertical" />
          <a @click="$refs.browse.getList(record.id)">
            <a-icon type="search" />執行記錄
          </a>
        </span>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 條`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAdv,listAdv, delAdv,changeStatus } from '@/api/platform/adv'
import CreateForm from './modules/CreateForm'
import Browse from './modules/browse'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Adv',
  components: {
    CreateForm,
    Browse
  },
  mixins: [tableMixin],
  // dicts: ['sys_user_sex'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: null,
        putInTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '主键',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '投放名稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '余額',
          dataIndex: 'balance',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value){
              return value;
            }else{
              return "-";
            }
          }
        },
        {
          title: '充值金額',
          dataIndex: 'recharge',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '點擊壹次價格',
          dataIndex: 'clickPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '展示壹次價格',
          dataIndex: 'exposurePrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '投放内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '視頻ID',
          dataIndex: 'videoId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '投放状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 0){
              return '投放中';
            }else{
              return "下架";
            }
          }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
      
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询广告投放列表 */
    getList () {
      this.loading = true
     pageAdv(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        nickname: undefined,
        putInTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAdv(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleStatus(row,status){
      var that = this
      let tit = status == 1 ? '下架': '上架'
      this.$confirm({
        title: '确认'+tit+'所选中数据?',
        content: '当前选中编号为' + row.id + '的数据',
        onOk () {
          return changeStatus(row.id)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('adv/adv/export', {
            ...that.queryParam
          }, `广告投放_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
