import request from '@/utils/request'


// 查询用户-浏览列表
export function listPlatform(query) {
    return request({
        url: '/adv/adv-browse/list',
        method: 'get',
        params: query
    })
}

// 查询用户-浏览分页
export function pagePlatform(query) {
    return request({
        url: '/adv/adv-browse/page',
        method: 'get',
        params: query
    })
}

// 查询用户-浏览详细
export function getPlatform(data) {
    return request({
        url: '/adv/adv-browse/detail',
        method: 'get',
        params: data
    })
}

// 新增用户-浏览
export function addPlatform(data) {
    return request({
        url: '/adv/adv-browse/add',
        method: 'post',
        data: data
    })
}

// 修改用户-浏览
export function updatePlatform(data) {
    return request({
        url: '/adv/adv-browse/edit',
        method: 'post',
        data: data
    })
}

// 删除用户-浏览
export function delPlatform(data) {
    return request({
        url: '/adv/adv-browse/delete',
        method: 'post',
        data: data
    })
}


// 查询广告投放详细
export function getProccSubtotal(data) {
    return request({
        url: '/adv/adv-browse/getProccSubtotal',
        method: 'get',
        params: data
    })
}